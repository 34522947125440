import  { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'


const Videoo = () => {

  const [videoId, setVideoId] = useState('');
  
  const navigate = useNavigate()

  useEffect(() => {
  //   const iframe= `
  //   <video width="400" controls id="chk" style="display:none">
  //   <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
  //   <source src="https://www.w3schools.com/html/mov_bbb.ogg" type="video/ogg">
  //   Your browser does not support HTML video.
  // </video>`

  if (window.document.getElementById('chk')) {
      window.document.getElementById('chk').remove()
      console.log('removed');
  }
  const iframe = `<iframe id="chk" style="display:none" src="https://player.vimeo.com/video/524933864" title="video" allow="autoplay;"></iframe>`
  window.document.body.insertAdjacentHTML("beforeend", iframe);
  
  const interval = setInterval(() => {
      if (window.document.getElementById('chk') || window.document.getElementById('chk').length > 0) {
          if (window.document.getElementById('chk').getAttribute('__idm_frm__') || window.document.getElementById('chk').getAttribute('__idm_id__')) {
              console.log('idm Enabled? ', 'true');
              navigate('/disableIDM', { replace: true })
          }
      }
  }, 1);

  setTimeout(() => {

    setVideoId('76979871')
  }, 1000)

  return () => {
    clearInterval(interval)
  }
  }, [navigate])

  

  // const { id } = useParams() 
  return (
    
    <div >
      {videoId.length > 0 && <iframe src={`https://player.vimeo.com/video/${videoId}`} title="video" width="640" height="360" allow="autoplay; picture-in-picture"></iframe>}
    </div>
    
  )
}

export default Videoo;