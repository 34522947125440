import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faBook, faCircleQuestion, faCartShopping, faVideo, faCalendar } from '@fortawesome/free-solid-svg-icons';
import '../styles/sidebarProfile.css'





function SidebarProfile() {

    // const[isOpen ,setIsOpen] = useState(false);
    // const toggle = () => setIsOpen (!isOpen);
    
    // const menuItem=[
    //   {
    //     path:"/profile",
    //     name:"ملف الطالب",
    //     icon: <i className="fa-solid fa-house" ></i>
    //   },
    //   {
    //     path:"/profile/courses",
    //     name:"  الكورسات  ",
    //     icon: <i className="fa-solid fa-book"></i>
    //   },
    //   {
    //     path:"/profile/quiz",
    //     name:"نتائج الامتحانات",
    //     icon: <i className="fa-solid fa-circle-question"></i>
    //   },
    //   {
    //     path:"/profile/homeworks",
    //     name:"نتائج الواجبات",
    //     icon: <i className="fa-solid fa-circle-question"></i>
    //   },
    //   {
    //     path:"/chargewallet",
    //     name:"الاشتراكات",
    //     icon: <i className="fa-sharp fa-solid fa-cart-shopping"></i>
    //   },
    // ]
    return (
        <>
                {/* <div className="sidebar-profile">
                <div className="navigation">
    <ul>
            {
                menuItem.map((item , index) =>(
                        <li key={index} className="list">

                            <b></b>
                            <b></b>
                            <Link to={item.path}>
                                <span className="icon">{item.icon}</span>
                                <span className="title">{item.name}</span>
                            </Link>
                        </li>
                ))
            }
    </ul>
  </div>

                </div> */}

<div className="sidebar-profile-main">
                    <ul>
                      <li>
                        <Link to="/profile">
                          <span>الملف الشخصي</span>
                          <FontAwesomeIcon icon={faHouse} />
                           </Link>
                           </li>
                           <li>
                           <Link to="/profile/courses">
                          <span>الكورسات</span>
                          <FontAwesomeIcon icon={faBook} />
                           </Link>
                           </li>
                        <li>
                        <Link to="/profile/quiz"> 
                        <span>درجات الامتحانات</span>
                        <FontAwesomeIcon icon={faCircleQuestion} />

                        </Link>
                        </li>
                      <li>
                      <Link to="/profile/homeworks"> 
                        <span>درجات الواجبات</span>
                        <FontAwesomeIcon icon={faCircleQuestion} />
                        </Link>
                      </li>
                      <li>
                      <Link to="/profile/videos"> 
                        <span> فيديوهات الواجبات </span>
                        <FontAwesomeIcon icon={faVideo} />
                        </Link>
                      </li>
                      <li>
                      <Link to="/profile/wallet"> 
                        <span> الأشتراكات</span>
                        <FontAwesomeIcon icon={faCartShopping} />
                        </Link>
                      </li>
                      <li>
                      <Link to="/profile/attendance"> 
                        <span> الحضور والغياب</span>
                        <FontAwesomeIcon icon={faCalendar} />
                        </Link>
                      </li>
                  
                       
                  
                    </ul>
                  </div>
 
        </>
    )
}

export default SidebarProfile;